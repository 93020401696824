import {useStaticQuery, graphql} from 'gatsby';
import React from 'react';
import {Button} from '../../../components/button/button';
import {mark} from '../../../utils/marked';
import st from './video.module.scss';

export const Video = () => {
  const {
    restApiCommunityPage: {
      Banner_section: {Background_image, button, Description, Title, Partner_logos},
    },
  } = query();

  return (
    <>
      <div className={st.wrapper}>
        <video src={Background_image.url} autoPlay={true} muted={true} loop={true} />
        <div className={st.section__wrapper}>
          <div className={st.section}>
            <div className={st.title} dangerouslySetInnerHTML={mark(Title)}></div>
            <div className={st.subtitle} dangerouslySetInnerHTML={mark(Description)}></div>
            <div className={st.buttons__wrapper}>
              <Button to={button?.url}>{button?.text}</Button>
            </div>
          </div>
          <div className={st.banners}>
            {Partner_logos.map((logo, index) => (
              <div className={st.banner} key={index}>
                <img src={logo.url} alt={logo.name} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiCommunityPage {
        Banner_section {
          Background_image {
            url
            name
          }
          button {
            text
            url
          }
          Description
          Partner_logos {
            name
            url
          }
          Title
        }
      }
    }
  `);
