import {Adventure} from './adventure/adventure';
import {BeAPart} from './be-a-part/be-a-part';
import {Mission} from './mission/mission';
import {Opinions} from './opinions/opinions';
import {Video} from './video/video';
import React from 'react';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';
import {Career} from './career/career';
import {FAQ} from './faq/faq';
import {Divider} from '../../components/divider/divider';
import st from './community.module.scss';
import {BhtCommunity} from './bht-community/bht-community';

export const Community = () => {
  const {
    restApiCommunityPage: {seo},
  } = query();
  return (
    <>
      <Helmet meta={[{name: 'description', content: seo.description}]}>
        <title>{seo.title}</title>
      </Helmet>
      <Video />
      <BhtCommunity />
      <Divider />
      <Mission />
      <Adventure />
      <Career />
      {/*
      <Divider />
      <Different />

      <Flexibility />
      <Divider /> */}
      <Divider stClassName={st.divider} />
      <Opinions />
      <BeAPart />
      {/* <InTouch /> */}
      <FAQ />
    </>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiCommunityPage {
        seo {
          title
          description
        }
      }
    }
  `);
