import React from 'react';
import st from './mission.module.scss';

export const Mission = () => {
  return (
    <div className={st.section}>
      <div className={st.content}>
        <h2 className={`${st.title} ${st.title_phone}`}>
          <span className={st.blue}>3 Facts You Need to Know </span>

          <span>Before Hitting the Join Us Button</span>
        </h2>
        <div className={st.content__image}>
          <img src={'/candidate.svg'} alt='candidate' />
        </div>
        <div className={st.content__text}>
          <h2 className={st.title}>
            <span className={st.blue}>3 Facts You Need to Know </span>

            <span>Before Hitting the Join Us Button</span>
          </h2>
          <div className={st.content}>
            <div className={st.subtitle}>We aren't your employer</div>
            <div className={st.point}>
              We are a business partner who supports you in career development and connects to international projects
              that match your personality traits.
            </div>
            <div className={st.subtitle}>We aren't a recruitment agency</div>
            <div className={st.point}>
              We accept projects only from trustworthy companies and guarantee you timely payments. We care about your
              potential and who we work with.
            </div>
            <div className={st.subtitle}>We aren't focused on fast growth</div>
            <div className={st.point}>
              We ensure that everyone in the Community represents our values. We only gather people who are the best in
              their ﬁeld and passionate about technologies.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
