import React from 'react';
import {Button} from '../../../components/button/button';
import {URLS} from '../../../components/nav/nav';
import st from './adventure.module.scss';

export const Adventure = () => {
  return (
    <div className={st.section}>
      <div className={`${st.section__wrapper} ${st.desktop}`}>
        <h2 className={st.title}>
          Become a Member of the
          <br />
          <strong>Elite IT Community</strong> to:
        </h2>
        <div className={st.cards}>
          {cards.map(({img, title, content}) => (
            <div className={st.card} key={title}>
              <img src={img} />
              <div className={st.card_title}>{title}</div>
              <div className={st.card_subtitle}>{content}</div>
            </div>
          ))}
        </div>
        <div className={st.bottom_wrapper}>
          <h2 className={st.subtitle}>
            Ready for the
            <br />
            <strong>Tech Adventure?</strong>
          </h2>
          <Button to={URLS.JOIN}>Join Us</Button>
        </div>
      </div>

      <div className={`${st.section__wrapper} ${st.mobile}`}>
        <h2 className={st.title}>
          Become a Member of the
          <br />
          <strong>Elite IT Community</strong> to:
        </h2>
        <div className={st.cards}>
          {cards.map(({img, title, content}, i, arr) => (
            <div className={st.card_wrapper} key={title}>
              <div className={st.card}>
                <img src={img} />
                <div className={st.card_title}>{title}</div>
                <div className={st.card_subtitle}>{content}</div>
              </div>
              {arr.length !== i + 1 ? <img src={'/line_1.svg'} /> : <img className={st.image} src={'/area_1.svg'} />}
            </div>
          ))}
        </div>
        <div className={st.bottom_wrapper}>
          <h2 className={st.subtitle}>
            Ready for the
            <br />
            <strong>Tech Adventure?</strong>
          </h2>
          <Button to={URLS.JOIN}>Join Us</Button>
        </div>
      </div>
    </div>
  );
};

const cards = [
  {
    img: '/adv_1.svg',
    title: 'Care',
    content: 'Work alongside experienced developers who care about the quality and want to deliver the best results.',
  },
  {
    img: '/adventure_2.svg',
    title: 'Decide',
    content: 'Take advantage of flexible workingtime to take care of your work-life balance and work 100% remotely.',
  },
  {
    img: '/adv_3.svg',
    title: 'Work',
    content: 'Engage in development projects in innovative areas and participate in the process of choosing them.',
  },
  {
    img: '/adv_4.svg',
    title: 'Grow',
    content: 'Use your full potential while working on challenging projects and build your personal brand with us.',
  },
  {
    img: '/adv_5.svg',
    title: 'Share',
    content: 'Become a valuable member of our IT Community where all members share technical knowledge and advice.',
  },
];
