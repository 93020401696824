import {useStaticQuery, graphql} from 'gatsby';
import React from 'react';
import {mark} from '../../../utils/marked';
import st from './be-a-part.module.scss';

export const BeAPart = () => {
  const {
    restApiCommunityPage: {
      become_a_part: {title, subtitle, image},
    },
  } = query();

  return (
    <div className={st.section}>
      <div className={st.section__wrapper}>
        <h2 className={st.title} dangerouslySetInnerHTML={mark(title)} />
        <div className={st.subtitle} dangerouslySetInnerHTML={mark(subtitle)} />
      </div>
      <div className={st.image} style={{backgroundImage: `url(${image?.url})`}} />
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiCommunityPage {
        become_a_part {
          subtitle
          title
          image {
            url
            name
          }
        }
      }
    }
  `);
