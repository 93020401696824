import React from 'react';
import {Button} from '../../../components/button/button';
import {URLS} from '../../../components/nav/nav';
import st from './career.module.scss';

export const Career = () => {
  return (
    <div className={st.section}>
      <div className={st.section__wrapper}>
        <h2 className={st.title}>
          Follow the Blue House
          <br />
          <strong>Career Development Path:</strong>
        </h2>
        <div className={st.step}>
          <div className={st.step_title}>Skills and Culture Assessment</div>
          <ul className={st.list}>
            <li>CV or LinkedIn profile review</li>
            <li>Technical skills verification</li>
            <li>Culture fit verification</li>
            <li>Conversation with our IT leaders or Founders</li>
          </ul>
        </div>
        <div className={`${st.step} ${st.step_2}`}>
          <div className={st.step_title}>Community Membership</div>
          <ul className={st.list}>
            <li>Engagement in projects tailored to skills and interests</li>
            <li>Guarantee of stable income and timely payments</li>
            <li>Possibility to work in the co-working space in your city</li>
            <li>Access to an IT Community with a broad knowledge base</li>
          </ul>
        </div>
        <div className={`${st.step} ${st.step_3}`}>
          <div className={st.step_title}>Partnership and Growth</div>
          <ul className={st.list}>
            <li>Support from Blue House Experts in building a personal brand</li>
            <li>Opportunity to become a speaker, meetup host, or technical writer</li>
            <li>Take part in the Blue House affiliate programs</li>
            <li>Possibility to receive career development advice</li>
          </ul>
        </div>
        <div className={st.button}>
          <Button to={URLS.JOIN}>Join Us</Button>
        </div>
      </div>
      <div className={st.section__wrapper__mobile}>
        <div className={st.image}>
          <img src='/career_mobile_0.svg' />
        </div>
        <h2 className={st.title}>
          Follow the Blue House
          <br />
          <strong>Career Development Path:</strong>
        </h2>
        <div className={st.image_1}>
          <img src='/career_mobile_1.svg' />
        </div>
        <div className={st.step}>
          <div className={st.step_title}>Skills and Culture Assessment</div>
          <ul className={st.list}>
            <li>CV or LinkedIn profile review</li>
            <li>Technical skills verification</li>
            <li>Culture fit verification</li>
            <li>Conversation with our IT leaders or Founders</li>
          </ul>
        </div>
        <div className={st.image_2}>
          <img src='/career_mobile_2.svg' />
        </div>
        <div className={`${st.step} ${st.step_2}`}>
          <div className={st.step_title}>Community Membership</div>
          <ul className={st.list}>
            <li>Engagement in projects tailored to skills and interests</li>
            <li>Guarantee of stable income and timely payments</li>
            <li>Possibility to work in the co-working space in your city</li>
            <li>Access to an IT Community with a broad knowledge base</li>
          </ul>
        </div>
        <div className={st.image_3}>
          <img src='/career_mobile_3.svg' />
        </div>
        <div className={`${st.step} ${st.step_3}`}>
          <div className={st.step_title}>Partnership and Growth</div>
          <ul className={st.list}>
            <li>Support from Blue House Experts in building a personal brand</li>
            <li>Opportunity to become a speaker, meetup host, or technical writer</li>
            <li>Take part in the Blue House affiliate programs</li>
            <li>Possibility to receive career development advice</li>
          </ul>
        </div>
        <div className={st.image}>
          <img src='/career_mobile_4.svg' />
        </div>
        <div className={st.button}>
          <Button to={URLS.JOIN}>Join Us</Button>
        </div>
      </div>
    </div>
  );
};
