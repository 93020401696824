import React from 'react';
import {Layout} from '../components/layout/layout';
import {Community} from '../pages-components/community/community';

const CommunityPage = () => (
  <Layout>
    <Community />
  </Layout>
);

export default CommunityPage;
