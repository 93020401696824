import {useStaticQuery, graphql} from 'gatsby';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import React, {Fragment} from 'react';
import st from './opinions.module.scss';
import {mark} from '../../../utils/marked';

const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1200},
    items: 1,
  },
  mobile: {
    breakpoint: {max: 1199, min: 0},
    items: 1,
  },
};

const CustomRightArrow = props => {
  return <button {...props} className={st.button__right} />;
};

const CustomLeftArrow = props => {
  return <button {...props} className={st.button__left} />;
};

export const Opinions = () => {
  const {
    restApiCommunityPage: {
      Coworkers_section: {Coworkers_description, Coworkers_header, Coworkers_coworkers},
    },
  } = query();

  return (
    <div className={st.section}>
      <h2 className={st.title} dangerouslySetInnerHTML={mark(Coworkers_header)}></h2>
      <div className={st.subtitle} dangerouslySetInnerHTML={mark(Coworkers_description)}></div>
      <Carousel
        responsive={responsive}
        ssr={true}
        infinite={true}
        removeArrowOnDeviceType={['mobile']}
        className={st.carousel}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {Coworkers_coworkers.map((coworkerData, index) => (
          <Fragment key={index}>
            <CoworkerCard coworkerData={coworkerData} />
          </Fragment>
        ))}
      </Carousel>
    </div>
  );
};

const CoworkerCard = ({coworkerData}) => {
  const {Avatar, Opinion, IsCurrentlyAt, Logo, Name} = coworkerData;

  return (
    <div className={st.card}>
      <div className={st.card__wrapper}>
        <img className={st.card__avatar} src={Avatar?.url} alt={Avatar?.name} />
        <div className={st.card__text}>
          <h3 className={st.card__name}>{Name}</h3>
          <p className={st.card__description}>{Opinion}</p>
        </div>
        <div className={st.card__where}>
          <p className={st.card__currently}>{IsCurrentlyAt ? 'Currently at:' : 'Previously at:'}</p>
          <img className={st.card__logo} src={Logo?.url} alt={Logo?.name} />
        </div>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiCommunityPage {
        Coworkers_section {
          Coworkers_description
          Coworkers_header
          Coworkers_coworkers {
            Name
            IsCurrentlyAt
            Avatar {
              url
              name
            }
            Logo {
              url
              name
            }
            Opinion
          }
        }
      }
    }
  `);
