import React, {useState} from 'react';
import st from './faq.module.scss';
export const FAQ = () => {
  const [length, setLength] = useState(3);

  return (
    <div className={st.section}>
      <h2 className={st.title}>Frequently Asked Questions</h2>
      {faqArray.map((f, index) => {
        if (index >= length) {
          return;
        }
        return <Card f={f} key={f.q} />;
      })}
      <div className={st.show}>
        {length <= faqArray.length ? (
          <div className={st.button} onClick={() => setLength(p => p + 3)}>
            See more
          </div>
        ) : null}
      </div>
      <h2 className={st.title_2}>Still have questions?</h2>
      <div className={st.subtitle}>
        If you cannot find answer to your question in our FAQ, you can always contact us. We will answer to you shortly!
      </div>
    </div>
  );
};

const Card = ({f}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={`${st.card} ${!open ? st.hidden : ''}`} onClick={() => setOpen(!open)}>
      <div className={st.card_header}>
        <div className={st.card_title}>{f.q}</div>
        <div className={st.card_mark}>{open ? '-' : '+'}</div>
      </div>
      <div className={`${st.card_content}`}>
        {f.a.map(a => (
          <div key={a} className={st.card_content_paragraph}>
            {a}
          </div>
        ))}
      </div>
    </div>
  );
};

const faqArray = [
  {
    q: 'What can I expect after sending over my CV?',
    a: [
      'First and foremost, the main way to join the Blue House Community is to be referred. So if you know someone in our Community, ask that person for a recommendation. If not, then we also accept applications from people that are not yet vouched for by our current Community Members in order to not limit ourselves to exceptional talent.',
      'After receiving your CV, we will look carefully at your experience and earlier projects. We always reply, however, we will invite you for the interview only if you are a good fit.',
    ],
  },
  {
    q: 'What kind of projects can I expect?',
    a: [
      'Our Blue House Community members are engaged in challenging projects that give them opportunities to grow. We do our best to understand your expectations and match you with projects where you will thrive.',
      'We work with a variety of organizations - both corporate and startups - in many different industries. You may expect both long-term and short-term projects where you are either fully or partially allocated. The most important thing, however, is that it is all up to you.',
      'We always prioritize engaging development projects in innovative areas as opposed to boring maintenance legacy projects that everyone hates.',
    ],
  },
  {
    q: 'If I start working with you, is there a guarantee that my full capacity will be fully allocated at all times?',
    a: [
      'We try our best to keep the project pipeline full so that our Community Members can always pick up a new project. However, there might be times when there won’t be any great fit for you just as well as there might be times when you’d like to focus on other projects and passions.',
    ],
  },
  {
    q: 'Once I am onboarded to a project, what kind of support can I expect from the Blue House Community?',
    a: [
      'Blue House Community is built by people who want to make other people better. We encourage open communication, knowledge sharing, and feedback culture. We have internal projects and ideas to provide continuous support to our Community Members. Being part of the Blue House Community means that you will also be expected to share your knowledge and give back to the Community.',
    ],
  },
  {
    q: `Wouldn't I make more money working for the same clients directly?`,
    a: [
      'It depends. Operating as a service provider to our client organisations we fall under different budgets and approvals than freelancers and permanent employees. The rates for service providers and agencies are usually much higher than those set for individual contractors.',
      'Because of that and because we keep our margins very low, we are usually able to offer our consultants higher rates than they could ever negotiate directly with our clients.',
    ],
  },
  {
    q: `What are your payment policies?`,
    a: [
      'Most of the time, you will invoice us on a monthly basis based on timesheets approved by the client(s). You can expect the invoices to be paid within 30 days of the issue date.',
    ],
  },

  {
    q: `May delays in payment from the client cause delays in payment from Blue House?`,
    a: ['No, we take full responsibility for paying your approved invoices on time.'],
  },
  {
    q: `I don't have a registered company. Can I still work with Blue House?`,
    a: [
      'You don’t need to act as a registered company in order to work with us. The details may vary based on your location but our Community Managers will be happy to provide more information.',
    ],
  },
  {
    q: `How is Blue House making money?`,
    a: [
      'We have two main revenue streams. For most projects, we make our profit from a small margin added to your hourly rate. Sometimes our Clients pay us for additional services and priority access to our Community Members.',
    ],
  },
  {
    q: `Working with international organisations I encountered problems with VAT returns and cash flows. Do you solve that problem?`,
    a: [
      'If you are based in Poland, you will invoice us in PLN + VAT - even if the client is abroad. For other Community Members, reverse charge rules apply for VAT. We take full responsibility for paying your approved invoices on time.',
    ],
  },
];
