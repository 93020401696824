import React from 'react';
import st from './bht-community.module.scss';

export const BhtCommunity = () => {
  return (
    <div className={st.section}>
      <h2 className={st.title}>
        <strong>Blue House Community</strong> Is For You
      </h2>
      <h3 className={st.h3}>We are a global network of IT Experts who support each other</h3>
      <div className={st.subtitle}>
        This IT Community gathers professionals who want to engage in challenging projects without a several-year
        commitment to one company. We offer you the possibility to decide what projects suit you best but in return, we
        expect engagement and quality. Become one of us!
      </div>
    </div>
  );
};
